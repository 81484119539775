import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuditAccessRoleBasedService } from '../audit-maintenance/services/audit-access-role-based.service';
import { AppRoutes } from '../shared/enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class TagForAuditGuard implements CanActivate {
  constructor(private auditAccessRoleBasedService: AuditAccessRoleBasedService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.auditAccessRoleBasedService.canTagForAudit().subscribe((result) => {
      if (!result) this.router.navigate([AppRoutes.LANDING_PAGE]);
      return result;
    });
    return this.auditAccessRoleBasedService.canTagForAudit();
  }
}
