import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  AdminPersonnel,
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCodesQuery,
  PricingCode,
  PricingWorkbenchApiService,
} from '@xpo-ltl/sdk-pricingworkbench';
import { User } from '@xpo-ltl/sdk-common';
import { UserService } from '../shared/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class PricingWorkbenchService {
  private pricingCodesCache$: Observable<any[]>;

  private loggedInUser: Observable<User>;
  public loggedInAnalystDetail$ = new BehaviorSubject<AdminPersonnel>(null);
  public loggedInAnalystDetailData = this.loggedInAnalystDetail$.asObservable();

  public analysts$ = new BehaviorSubject<AdminPersonnel[]>(null);
  public analystsData = this.analysts$.asObservable();

  private pricingCategories: string[] = ['Pricing'];
  private pricingSubCategories: string[] = ['USState', 'CNState'];

  constructor(private pricingWorkbenchApiService: PricingWorkbenchApiService,
              private userService: UserService) {
    this.loggedInUser = this.userService.getUser();
  }

  private listPricingCodes() {
    const query = new ListPricingCodesQuery();
    query.category = this.pricingCategories;
    query.subCategory = this.pricingSubCategories;
    return this.pricingWorkbenchApiService.listPricingCodes(query).pipe(map((result) => result.pricingCodes));
  }

  getStatesCodes(): Observable<PricingCode[]> {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.listPricingCodes().pipe(
        map((result) => {
          result.filter((code) => {
            code.category;
          });
          return result;
        }),
        shareReplay()
      );
    }
    return this.pricingCodesCache$;
  }

  getPricingCodes(): Observable<PricingCode[]> {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.listPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }

  getAnalyst(): Observable<PricingCode[]> {
    const queryParams = new ListPricingCodesQuery();
    queryParams.category = ['SalesRfp'];
    queryParams.subCategory = ['AnalystAssignment'];
    return this.pricingWorkbenchApiService.listPricingCodes(queryParams).pipe(map((result) => result.pricingCodes));
  }

  listPricingAnalyst(employeeId?: string, levelOfDetail?: string, employeeRoleType?: string): Observable<AdminPersonnel[]> {
    const query = new ListPricingAnalystsQuery();
    query.employeeId = employeeId;
    query.levelOfDetail = levelOfDetail;
    query.employeeRoleType = employeeRoleType;
    return this.pricingWorkbenchApiService.listPricingAnalysts(query)
      .pipe(map((resp : ListPricingAnalystsResp) => {
        return resp.pricingAnalysts;
      }));
  }

   getLoggedInAnalyst()  {
    return this.loggedInUser.pipe(
      switchMap(user => {
        return this.listPricingAnalyst(user?.employeeId, 'Security');
      }),
      map((users) => users[0]
      ),
      tap((user) => this.loggedInAnalystDetail$.next(user)),
      shareReplay()
    );
  }

  public getAnalysts() {
    return this.loggedInAnalystDetailData.pipe(

      switchMap(user => {
        //const roleTypes = user?.additionalRoleType;
        const roleTypes = '1,2,9,10,11,99';
        return this.listPricingAnalyst(null, null, roleTypes)
      }),
      tap((user) => this.analysts$.next(user)),
    )
  }
}
