import { NgModule } from '@angular/core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ErrorPipe } from './pipes/error/error.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';
import { FooterComponent } from './components/footer/footer.component';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoFooterModule,
  XpoIconModule,
} from '@xpo-ltl/ngx-ltl-core';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { ReplaceCommaDirective } from './directives/replace-comma.directive';
import { SnackBarMessageComponent } from './components/snack-bar-message/snack-bar-message.component';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from '../app-notification.component';
import { GridAppliedFiltersComponent } from './components/grid-applied-filters/grid-applied-filters.component';
import { AppliedFiltersDisplayPipe } from './components/grid-applied-filters/helper/applied-filer.pipe';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FooterComponent,
    ReleaseNotesComponent,
    GridAppliedFiltersComponent,
    // Pipes/Directives
    ErrorPipe,
    YesNoPipe,
    ReplaceCommaDirective,
    SnackBarMessageComponent,
    NotificationComponent,
    AppliedFiltersDisplayPipe,
  ],
  imports: [
    CommonModule,
    XpoFooterModule,
    XpoIconModule,
    XpoDialogModule,
    XpoButtonModule,
    XpoCardModule,
    MatChipsModule,
    XpoChipsModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    FooterComponent,
    ReleaseNotesComponent,
    SnackBarMessageComponent,
    NotificationComponent,
    GridAppliedFiltersComponent,
    // Pipes/Directives
    ReplaceCommaDirective,
    AppliedFiltersDisplayPipe,
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
