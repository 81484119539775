import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { RfpStandaloneGuard } from './rfp-standalone/rfp-standalone-interceptor';
import { TagForAuditGuard } from './tag-for-audit/tag-for-audit.guard';

const routerDefinitions: Routes = [
  // {
  //   path: AppRoutes.LANDING_PAGE,
  //   loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  //   canActivate: [XpoAuthenticationGuard],
  // },
  /*{
    path: AppRoutes.STORAGE_ACCESSORIAL_CHARGES,
    loadChildren: () => import('./storage-ac/storage-ac.module').then((m) => m.StorageACModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.DETENTION_WITH_POWER,
    loadChildren: () =>
      import('./detention-with-power/detention-with-power.module').then((m) => m.DetentionWithPowerModule),
    canActivate: [XpoAuthenticationGuard],
  },*/
  {
    path: AppRoutes.RULESET_VIEW,
    loadChildren: () => import('./ruleset/ruleset.module').then((m) => m.RulesetModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.AGREEMENT_LIST,
    loadChildren: () => import('./pricing-agreement/pricing-agreement.module').then((m) => m.PricingAgreementModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.RULESET_BUILDER,
    loadChildren: () =>
      import('./complex-ruleset-builder/complex-ruleset-builder.module').then((m) => m.ComplexRulesetBuilderModule),
    canActivate: [XpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: AppRoutes.ACCESSORIAL_SERVICES,
    loadChildren: () => import('./ac-services/ac-services.module').then((m) => m.AcServicesModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.FSC_TEMPLATE,
    loadChildren: () => import('./fsc-template/fsc-template.module').then((m) => m.FscTemplateModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.ZONE_POINTS,
    loadChildren: () => import('./zone-points/zone-points.module').then((m) => m.ZonePointsModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.FUEL_PRICE,
    loadChildren: () => import('./fuel-price/fuel-price.module').then((m) => m.FuelPriceModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.AGREEMENTS,
    loadChildren: () => import('./offshore-agreement/offshore-agreement.module').then((m) => m.OffshoreAgreementModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.ACCESSORIAL_GROUPS,
    loadChildren: () => import('./ac-groups/ac-groups.module').then((m) => m.AcGroupsModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.MAINTENANCE_SALES,
    loadChildren: () =>
      import('./maintenance-sales-rfp/maintenance-sales-rfp.module').then((m) => m.MaintenanceSalesRfpModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: `${AppRoutes.TAG_FOR_AUDIT}`,
    loadChildren: () => import('./tag-for-audit/tag-for-audit.module').then((m) => m.TagForAuditModule),
    canActivate: [XpoAuthenticationGuard, TagForAuditGuard],
  },
  {
    path: `${AppRoutes.AUDIT_MAINTENANCE}`,
    loadChildren: () => import('./audit-maintenance/audit-maintenance.module').then((m) => m.AuditMaintenanceModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: `${AppRoutes.CUSTOMER_HIERARCHY}`,
    loadChildren: () => import('./customer-hierarchy/customer-hierarchy.module').then((m) => m.CustomerHierarchyModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: `${AppRoutes.STANDALONE_RFP}/:option/:customerId/:customerSuffix`,
    loadChildren: () => import('./rfp-standalone/rfp-standalone.module').then((m) => m.RfpStandaloneModule),
    canActivate: [RfpStandaloneGuard],
  },
  {
    path: `${AppRoutes.STANDALONE_RFP}/:option/:agreementId`,
    loadChildren: () => import('./rfp-standalone/rfp-standalone.module').then((m) => m.RfpStandaloneModule),
    canActivate: [RfpStandaloneGuard],
  },
  {
    path: `${AppRoutes.STANDALONE_RFP}`,
    loadChildren: () => import('./rfp-standalone/rfp-standalone.module').then((m) => m.RfpStandaloneModule),
    canActivate: [RfpStandaloneGuard],
  },
  {
    path: `${AppRoutes.STANDALONE_RFP}-${AppRoutes.SALES_ID}/:option/:salesRfpId`,
    loadChildren: () => import('./rfp-standalone/rfp-standalone.module').then((m) => m.RfpStandaloneModule),
    canActivate: [RfpStandaloneGuard],
  },
  {
    path: AppRoutes.PL_DOCS,
    loadChildren: () => import('./pl-docs/pl-docs.module').then((m) => m.PlDocsModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.STANDALONE_RULESET,
    loadChildren: () => import('./ruleset-standalone/ruleset-standalone.module').then((m) => m.RulesetStandaloneModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.RULESET_DETAIL,
    loadChildren: () => import('./ruleset-standalone/ruleset-standalone.module').then((m) => m.RulesetStandaloneModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: '',
    redirectTo: `${AppRoutes.AGREEMENT_LIST}/${AppRoutes.LIST}`,
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
